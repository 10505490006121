import { Injectable } from '@angular/core';
import { GetSicForPostalCodesQuery, GetSicForPostalCodesResp, LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { Envelope, ListInfo, SortField } from '@xpo-ltl/sdk-common';
import {
  GetMinimumChargesForCzarLiteTariffRqst,
  GetTariffRateBlockRqst,
  ListTariffsResp,
  ListTariffsRqst,
  Tariff,
  TariffManagementApiService,
  TariffRateBlock,
  TariffRateBlockFilter,
} from '@xpo-ltl/sdk-tariffmanagement';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TariffService } from '../tariff/tariff.service';

export interface MaxRecordsExceededMessage {
  resultCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class TariffDetailService {
  private maxRecordsDisplayedSubject: BehaviorSubject<MaxRecordsExceededMessage> = new BehaviorSubject({
    resultCount: 0,
  });
  maxRecordsDisplayed$ = this.maxRecordsDisplayedSubject.asObservable();

  get MaxRecordsDisplayed() {
    return this.maxRecordsDisplayedSubject.value.resultCount;
  }

  set MaxRecordsDisplayed(value: number) {
    this.maxRecordsDisplayedSubject.next({
      resultCount: value,
    });
  }

  constructor(
    private tariffManagementApiService: TariffManagementApiService,
    private tariffService: TariffService,
    private locationApiService: LocationApiService
  ) {}

  currentTariffSubject = new BehaviorSubject<Tariff>(null);
  currentTariff$ = this.currentTariffSubject.asObservable();

  get currentTariff() {
    return this.currentTariffSubject.value;
  }

  loadDetail(tariffData): void {
    this.currentTariffSubject.next(null);
    this.tariffService.getTariff(tariffData).subscribe((tariff) => {
      this.currentTariffSubject.next(tariff);
    });
  }

  getTariffRateBlocks(filters: TariffRateBlockFilter): Observable<TariffRateBlock[]> {
    const request = new GetTariffRateBlockRqst();
    request.tariffRateBlockFilter = filters;
    return this.tariffManagementApiService.getTariffRateBlock(request).pipe(
      map((data) => {
        const tariffRateBlocks = this.removeWhiteSpaces(data.tariffRateBlocks);
        return tariffRateBlocks;
      })
    );
  }

  removeWhiteSpaces(data: TariffRateBlock[]) {
    return data.reduce((acc, tariff) => {
      const nmfcClasses = tariff.nmfcClasses.reduce((acc2, nmfcClass) => {
        const weightBrackets = nmfcClass.weightBrackets.map((weightBracket) => {
          return { name: weightBracket.name.trim(), value: weightBracket.value };
        });
        nmfcClass.weightBrackets = weightBrackets;
        return [...acc2, nmfcClass];
      }, []);
      tariff.nmfcClasses = nmfcClasses;
      return [...acc, tariff];
    }, []);
  }

  getTariffRateBlockWithStates(
    filters: TariffRateBlockFilter,
    getWithStates: boolean = true
  ): Observable<TariffRateBlock[]> {
    const request = new GetTariffRateBlockRqst();
    request.tariffRateBlockFilter = filters;
    const postalCds = [filters.originZipCd, filters.destinationZipCd];
    if (getWithStates) {
      return this.getSicForPostalCodes(postalCds).pipe(
        switchMap((sicPostalResponse: any) => {
          if (sicPostalResponse) {
            const originStateCd = sicPostalResponse.postalSicAssignments?.find(
              (postalSic) => postalSic.postalCd === filters.originZipCd
            );
            const destinationStateCd = sicPostalResponse.postalSicAssignments?.find(
              (postalSic) => postalSic.postalCd === filters.destinationZipCd
            );
            request.tariffRateBlockFilter.originStateCd = originStateCd?.stateCd;
            request.tariffRateBlockFilter.destinationStateCd = destinationStateCd?.stateCd;
          }
          return this.tariffManagementApiService.getTariffRateBlock(request).pipe(
            map((data) => {
              const tariffRateBlocks = this.removeWhiteSpaces(data.tariffRateBlocks);
              return tariffRateBlocks;
            })
          );
        })
      );
    } else {
      return this.tariffManagementApiService.getTariffRateBlock(request).pipe(
        map((data) => {
          const tariffRateBlocks = this.removeWhiteSpaces(data.tariffRateBlocks);
          return tariffRateBlocks;
        })
      );
    }
  }

  getTariffs(): Observable<ListTariffsResp> {
    const request = new ListTariffsRqst();
    request.listInfo = new ListInfo();
    request.listInfo.levelOfDetail = 'rateFile';
    request.listInfo.startAt = 0;
    const sorting = new SortField();
    sorting.sortByFieldName = 'tariff';
    sorting.sortOrder = 'asc';
    request.listInfo.sortFields = [sorting];
    request.listInfo.numberOfRows = 10000;
    return this.tariffManagementApiService.listTariffs(request);
  }

  getSicForPostalCodes(postalCds: string[]) {
    const queryParams = new GetSicForPostalCodesQuery();
    queryParams.postalCds = postalCds;
    return this.locationApiService.getSicForPostalCodes(queryParams);
  }

  getMinimumChargesForCzarLiteTariff(request: GetMinimumChargesForCzarLiteTariffRqst) {
    return this.tariffService.getMinimumChargesForCzarLiteTariff(request);
  }
}
