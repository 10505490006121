import { Injectable } from '@angular/core';
import { ListInfo, TariffOperatorCd } from '@xpo-ltl/sdk-common';
import {
  GetMinimumChargesForCzarLiteTariffResp,
  GetMinimumChargesForCzarLiteTariffRqst,
  ListTariffsRqst,
  SendTariffRatesFileRqst,
  Tariff,
  TariffManagementApiService,
  TariffRateChange,
  UpdateTariffRateResp,
  UpdateTariffRateRqst,
} from '@xpo-ltl/sdk-tariffmanagement';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TariffFieldNames } from '../../enums';
import { FormatDateService } from '../format-date/format-date.service';

@Injectable({
  providedIn: 'root',
})
export class TariffService {
  constructor(
    private tariffManagementApiService: TariffManagementApiService,
    private formatDateService: FormatDateService
  ) {}

  getTariffs(request: ListTariffsRqst) {
    return this.tariffManagementApiService.listTariffs(request);
  }

  getTariff(tariffData): Observable<Tariff> {
    const request: ListTariffsRqst = new ListTariffsRqst();
    request.listInfo = new ListInfo();
    request.listInfo.startAt = 0;
    request.listInfo.numberOfRows = 1;
    const expirationData = this.formatDateService.getDateFromJulian(tariffData[TariffFieldNames.expirationDate]);
    const effectiveDate = this.formatDateService.getDateFromJulian(tariffData[TariffFieldNames.effectiveDate]);
    request.tariffFilters = [
      {
        fieldName: TariffFieldNames.tariffCode,
        fieldValue: tariffData[TariffFieldNames.tariffCode],
        tariffOperatorCd: TariffOperatorCd.EQUAL_TO,
      },
      {
        fieldName: TariffFieldNames.effectiveDate,
        fieldValue: effectiveDate,
        tariffOperatorCd: TariffOperatorCd.EQUAL_TO,
      },
      {
        fieldName: TariffFieldNames.expirationDate,
        fieldValue: expirationData,
        tariffOperatorCd: TariffOperatorCd.EQUAL_TO,
      },
    ];

    return this.tariffManagementApiService.listTariffs(request).pipe(
      map((data) => {
        return data.tariffs[0];
      })
    );
  }

  updateTariffRate(tariffRateChange: TariffRateChange): Observable<UpdateTariffRateResp> {
    const request = new UpdateTariffRateRqst();
    request.tariffRateChange = tariffRateChange;
    return this.tariffManagementApiService.updateTariffRate(request);
  }

  sendTariffRatesFile(request: SendTariffRatesFileRqst): Observable<void> {
    return this.tariffManagementApiService.sendTariffRatesFile(request);
  }

  getMinimumChargesForCzarLiteTariff(
    request: GetMinimumChargesForCzarLiteTariffRqst
  ): Observable<GetMinimumChargesForCzarLiteTariffResp> {
    return this.tariffManagementApiService.getMinimumChargesForCzarLiteTariff(request);
  }
}
